@import "helpers/mixins";
@import "helpers/input";

.index-slider{
    max-height: 700px;
    min-height: 600px;
    @include adaptive(768){
        max-height: 200px;
        min-height: unset;
    }
    &__title{
        width: 65%;
        background-color: rgba(93, 64, 156, 0.8);
        padding: 40px 140px 50px 50px;
        position: absolute;
        left: 0;
        bottom: 0;
        color:white;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 50px;
        font-weight: 700;
        line-height: 66px;
        text-transform: uppercase;
        letter-spacing: 1px;
        max-width: 1190px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        @include adaptive(1600){
            font-size: 24px;
            padding: 20px 60px 20px 20px;
            width: auto;
        }
        @include adaptive(768){
            width: 100%;
            font-size: 18px;
            line-height: normal;
        }

        &:hover, &:focus{
            color:white;
            text-decoration: none;
        }
        &::after{
            content: '';
            width: 60px;
            height: 60px;
            box-shadow: 0 6px 6px rgba(0, 0, 0, 0.35);
            background-color: #ffffff;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='21' height='34' viewBox='0 0 21 34'%3E%3Cg%3E%3Cg transform='translate(-1180 -761)'%3E%3Cimage width='21' height='34' transform='translate(1180 761)' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAiCAYAAACwaJKDAAAAuklEQVRIS83WzQnDMAwF4Jdtu0E7QrpAMmc3KII8akziPP0YqpPtw4esg6QFv1gAbAA+AF7Nu/tokAXBx3F/Z2DDepCZhWEDdwDMsP9qCDZ0BfAcFM4Ns6alMFFLtAxu0TK4R0vgMzQNX6EpeISG4Ts0BCuoG1ZRF+xBZdiLSvBfoFJ/8GQqgRwjygySQRV1gQrqBu/QEDhCw+AVmgLP0DTYoyVgi5aBREtBolPWnikL2pRVkg2lbOn9AlRVQzPDEVXqAAAAAElFTkSuQmCC'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            @include adaptive(768){
                transform: translate(0, -50%);
            }
        }
    }
    &__slide{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img{
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
        }
    }
}
.index-form{
    position: absolute;
    width: 600px;
    height: 600px;
    background-color: rgba(255,255,255,0.85);
    top: 50px;
    right: 50px;
    bottom: 50px;
    z-index: 2;
    @include adaptive(1600){
        bottom: 150px;
        height: auto;
    }
    @include adaptive(768){
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
        width: 100%;

    }
    &__title{
        font-size: 36px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 40px 150px 30px 50px;
        letter-spacing: 0.72px;
        color: #5d409c;
        line-height: 46px;
        @include adaptive(1600){
            padding: 25px;
            font-size: 28px;
            line-height: normal;
            text-align: center;
        }
        @include adaptive(480){
            font-size: 22px;
        }
    }
    &-count{
        box-shadow: 0 6px 32px rgba(0, 0, 0, 0.27);
        background-color: #e9f5ed;
        padding: 0 55px;
        font-size: 26px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.52px;
        display: flex;
        color: #54cb79;
        align-items: center;
        @include adaptive(480){
            padding: 10px 20px;
            font-size: 18px;
        }
        &__value{
            margin-left: 10px;
            font-size: 46px;
            color: #5d409c;
            @include adaptive(480){
                font-size: 22px;
            }
        }
    }
    &__row{
        padding: 0 50px;
        margin-top: 40px;
        @include adaptive(480){
            padding: 0 20px;
        }
    }
    &__button{
        margin-top: 50px;
    }
}
