.input {
    $parent: &;

    &-required {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s;
        font-weight: 700;
        font-size: 16px;
        user-select: none;
    }

    &-label {
        width: 100%;
        color: black;
        font-size: 16px;
        position: relative;
        & span:first-of-type {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s;
            font-weight: 700;
            font-size: 16px;
            user-select: none;
            pointer-events: none;
            @include adaptive(480){
                font-size: 14px;
            }
        }

        &_required {
            #{$parent}-field {
                &:focus ~ #{$parent}-required {
                    right: 0;
                    display: block;
                    top: 0;
                }

                &:focus ~ span {

                }
            }
        }
    }

    &-field {
        width: 100%;
        font-size: inherit;
        color: inherit;
        background: transparent;
        border: none;
        border-bottom: 2px solid #5b5195;
        padding: 12px 0;
        letter-spacing: 1.6px;
        outline: none;
        &:focus + span:first-of-type, &_filled + span:first-of-type {
            top: 0;
        }
    }
}

.checkbox-check {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin: 0;
    $parent: &;

    &_black {
        & #{$parent}__text {
            color: black;
            font-size: 12px;
            line-height: 16px;
            opacity: 1;
        }

        & #{$parent}__span {
            border-color: rgb(18, 21, 20);

            &::before,
            &::after {
                background-color: rgb(18, 21, 20);
            }
        }
    }

    &__box:checked + &__span::after {
        width: 7px;
    }

    &__box:checked + .checkbox-check__span::before {
        width: 3px;
    }

    &__box:checked ~ .checkbox-check__text {
        opacity: 1;
    }

    &__box {
        line-height: 0;
        font-size: 0;
        position: absolute;
        visibility: hidden;
        opacity: 0;

        &:disabled {
            user-select: none;

            + #{$parent}__span {
                border-color: rgb(184, 184, 184);
                user-select: none;

                &::before,
                &::after {
                    opacity: 0;
                }
            }
        }
    }

    &__text {
        opacity: 0.41;
        color: rgb(230, 215, 189);
        font-size: 13px;
        transition: 0.3s;
        @include adaptive(1024) {
            line-height: 24px;
        }
        @include adaptive(900) {
            font-size: 13px;
        }

        & a {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            text-decoration: underline;
        }
    }

    &__span {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid rgb(230, 215, 189);
        display: block;
        flex-shrink: 0;
        margin-right: 12px;
        position: relative;
        &::before,
        &::after {
            content: '';
            width: 0;
            height: 1px;
            background-color: #c0c0c0;
            display: block;
            position: absolute;
            border-radius: 5px;
            transition: 0.6s;
        }

        &::before {
            top: 50%;
            left: 3px;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            top: 35%;
            right: 1px;
            transform: translate(50%, -50%) rotate(-45deg);
        }
    }

}

.button{
    width: 198px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    border: 0;
    user-select: none;
    transition: all .4s;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 16px;
    font-weight: 700;
    box-shadow: inset 2px 0 transparent;
    text-decoration: none;
    @include adaptive(480){
        width: 180px;
        height: 35px;
        font-size: 14px;
    }
    &_violet{
        background-color: #5b5195;
        color:white;
        &:hover{
            text-decoration: none;
            box-shadow: inset 0 0 0 2px #5b5195;
            color: #5b5195;
            background-color: white;
        }
    }
}
